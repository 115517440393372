import { loginDefault, loginValidation } from '../../validator/login';
import { Button, HStack, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLogin } from '../../hooks/auth';
import { useForm } from 'react-hook-form'
import CustomInput from '../form-components/CustomInput'
import CustomInputPassword from '../form-components/CustomInputPassword'
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: 'all',
        defaultValues: loginDefault,
        resolver: yupResolver(loginValidation),
    })

    const { mutate: login, isPending: loggingIn } = useLogin()

    const onSubmit = formData => {
        login(formData)
    };

    // const onSubmit = async (formData) => {
    //     login(formData)
    // }

    let navigate = useNavigate();
    return (
        <Stack spacing={8} className="w-[95%] md:w-[417px] bg-white rounded-[44px] shadow-custom p-[20px]">
            <Stack>
                <Text className="text-[24px] leading-[29px] font-bold">Sign in</Text>
                <HStack>
                    <Text>Are you new to AudioSweep?</Text>
                    <ChakraLink onClick={() => navigate('/register')} className='!text-[#3E4CF0]' fontWeight="bold">
                        Get started
                    </ChakraLink>
                </HStack>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6}>
                    <CustomInput placeholder="Email address" name={'email'} control={control} errors={errors} />
                    <CustomInputPassword placeholder="Password" name={'password'} control={control} errors={errors} />
                    <HStack justify={'space-between'}>
                        <Stack>
                            <HStack>
                                <ChakraLink
                                    onClick={() => navigate('/forgot_password')}
                                    className='!text-[#3E4CF0]'
                                    fontWeight={'bold'}>
                                    Forgot you password?
                                </ChakraLink>
                            </HStack>
                        </Stack>
                    </HStack>
                    <Button size={'lg'} type="submit" isLoading={loggingIn} className='!bg-[#3E4CF0] !text-white'>
                        Sign in
                    </Button>
                </Stack>
            </form>
        </Stack>
    )
}
