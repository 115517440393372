export const Check = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#EBEFF0" />
    <g clipPath="url(#clip0_452_68)">
      <path d="M11.8453 5.42682L6.92683 11.329L4.18848 8.51237L5.14447 7.58293L6.85056 9.33776L10.821 4.57324L11.8453 5.42682Z" fill="#B9BEC1" />
    </g>
    <defs>
      <clipPath id="clip0_452_68">
        <rect width="8" height="8" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
