import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: "https://backend.audiosweep.net/",
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        const _as_token = localStorage.getItem('_as_token');
        if (_as_token) {
            config.headers['Authorization'] = `Bearer ${_as_token}`
        }
        return config
    },
    error => {
        // Do something with request error
        return Promise.reject(error)
    }
)
    
// Add a response interceptor
axiosInstance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    response => {
        // socket.connect()
        return response
    },

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    error => {
        if (error.response.status === 401) {
            // window.location.href = '/users/logout'
            // throw new ApiError('Unauthorized', { cause: 401 })
        }

        return Promise.reject(error)
    }
)

export default axiosInstance
