import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import FormFieldWrapper from './FormFieldWrapper'


export default function CustomCheckbox(props) {
    return (
        <FormFieldWrapper {...props}>
            <Checkbox />
        </FormFieldWrapper>
    )
}
