import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import isAuthenticated from '../../utils/isAuthenticated';

export const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" replace />;
  };

export const PublicRoute = ({ children }) => {
    return isAuthenticated() ? <Navigate to="/sweeper" replace /> : children;
};