import axiosInstance from '../utils/axios'
import { isAxiosError } from 'axios'
export const uploadSong = async ({ payload }) => {
    try {
        const response = await axiosInstance.post(`/song/upload`, payload, {
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        })

        return response?.data
    } catch (error) {
        if (isAxiosError(error)) {
            return error.response?.data
        } else {
            console.error(error)
        }
    }
}

export const previewSong = async (id) => {
    try {
        const response = await axiosInstance.post(`/song/${id}/preview`)

        return response?.data
    } catch (error) {
        if (isAxiosError(error)) {
            return error.response?.data
        } else {
            console.error(error)
        }
    }
}

export const cleanExportSong = async (id) => {
    try {
        const response = await axiosInstance.post(`/song/${id}/clean`)

        return response?.data
    } catch (error) {
        if (isAxiosError(error)) {
            return error.response?.data
        } else {
            console.error(error)
        }
    }
}

export const patchSong = async (id, payload) => {
    try {
        const response = await axiosInstance.patch(`/song/${id}`, {manually_tagged:payload})

        return response?.data
    } catch (error) {
        if (isAxiosError(error)) {
            return error.response?.data
        } else {
            console.error(error)
        }
    }
}