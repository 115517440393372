import axiosInstance from '../utils/axios'
import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom';

// login
const login = (payload) => axiosInstance.post('/users/login', payload)

export const useLogin = () => {
    let navigate = useNavigate();
    const toast = useToast()

    return useMutation({
        mutationFn: login,
        onSuccess(response) {
            const data = get(response, 'data')
            const token = get(data, 'access_token')
            
            localStorage.setItem('_as_token', token);

            navigate('/sweeper')
        },
        onError(error) {
            const data = error.response?.data

            toast({
                title: 'Error',
                status: 'error',
                description: data.detail,
            })
        },
    })
}

// register
const register = (payload) => axiosInstance.post('/users/register', payload)

export const useRegister = () => {
    const toast = useToast()
    let navigate = useNavigate();
    return useMutation({
        mutationFn: register,
        onSuccess(response) {
            const data = get(response, 'data')

            toast({
                title: 'Success',
                status: 'success',
                description: data.message,
            })
            window.location.href = data.stripe.payment_link;
            navigate('/login')
        },
        onError(error) {
            const data = error.response?.data

            toast({
                title: 'Error',
                status: 'error',
                description: data.detail,
            })
        },
    })
}
