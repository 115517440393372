import Register from '../../components/user/Register'
import StandalonePage from '../../components/user/StandalonePage'

export default function GetStartedPage() {
    return (
        <>
            <StandalonePage>
                <Register />
            </StandalonePage>
        </>
    )
}
