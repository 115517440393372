import { HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axios';
import { stripe_cancel_url, stripe_success_url } from '../config';
import { Check } from './../icons';

export default function CheckoutPage() {
  const subscriptionDetails = [
    {
      name: 'Free',
      price: 0,
      desc: '3 credits per month (3 minutes of audio)',
      id: 'price_1PLnS52LtmGRxN73JoV6uQ1S',
    },
    {
      name: 'Creator',
      price: 6,
      desc: '100 credits per month (100 minutes of audio)',
      id: 'price_1PLnT62LtmGRxN73lGLGuGJ7',
    },
    {
      name: 'Pro',
      price: 9,
      desc: '500 credits per month (500 minutes of audio) ',
      id: 'price_1PLnTh2LtmGRxN73bSa4eDpt',
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      desc: 'Account representative',
      id: 'custom',
    },
  ];

  useEffect(() => {
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    if (userdata?.stripe_price_id) {
      setCurrentPlan(userdata.stripe_price_id);
    }
    axiosInstance
      .get('/users/')
      .then(res => {
        const userdata = res.data;
        setCurrentPlan(userdata.stripe_price_id);
        localStorage.setItem('userdata', JSON.stringify(userdata));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const [currentPlan, setCurrentPlan] = useState('');
  const [loadingPlan, setLoadingPlan] = useState(null); // Track loading state by plan ID

  const handleCurrentPlan = async planId => {
    if (currentPlan === planId) return;
    try {
      setLoadingPlan(planId); // Set loading to the specific plan
      const res = await axiosInstance.post(
        '/payment/create-subscription-link',
        {
          price_id: planId,
          stripe_success_url,
          stripe_cancel_url,
        }
      );
      window.location.href = res.data.payment_link;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPlan(null); // Reset loading state after operation
    }
  };

  return (
    <VStack
      spacing={4}
      alignItems="center"
      justifyContent="center"
      className="mt-[70px]"
    >
      <Text className="text-[#1D2127] font-[700] text-[40px] leading-[48px]">
        Purchase a subscription
      </Text>
      <Text className="text-[#52575D] font-[400] text-[20px] leading-[24px]">
        Choose the plan that works for you.
      </Text>
      <Text className="mt-[50px] bg-[#3E4CF0] text-white px-[50px] py-[10px] rounded-[100px] font-[400] text-[20px] leading-[24px]">
        Monthly
      </Text>

      <HStack className="!gap-[20px] mt-[70px]">
        {subscriptionDetails.map(subscription => (
          <div
            key={subscription.id}
            className="shadow-custom1 px-[20px] py-[35px] flex flex-col items-center rounded-[30px] w-[300px]"
          >
            <Text className="text-[#1D2127] font-[600] text-[18px] leading-[20px] w-full mb-[15px]">
              {subscription.name}
            </Text>
            <Text className="text-[#1D2127] font-[800] text-[36px] leading-[44px] w-full mb-[30px]">
              {subscription.price === 'Custom'
                ? 'Custom'
                : `£${subscription.price}`}
            </Text>
            <div className="flex gap-2 w-full">
              <Check />
              <Text className="text-[#1D2127] font-[300] text-[14px] leading-[16px] w-full mb-[20px]">
                {subscription.desc}
              </Text>
            </div>
            {subscription.id === 'custom' ? (
              <a
                href="mailto:support@audiosweep.net"
                className="w-full bg-[#F1EFFF] hover:bg-[#3E4CF0] text-[#3E4CF0] hover:text-white text-[16px] leading-[24px] font-[500] h-[40px] rounded-[8px] flex justify-center items-center"
              >
                Contact Us
              </a>
            ) : currentPlan === subscription.id ? (
              <button className="w-full bg-[#3E4CF0] text-white text-[16px] leading-[24px] font-[500] h-[40px] rounded-[8px]">
                Current Plan
              </button>
            ) : (
              <button
                className="w-full bg-[#F1EFFF] hover:bg-[#3E4CF0] text-[#3E4CF0] hover:text-white text-[16px] leading-[24px] font-[500] h-[40px] rounded-[8px]"
                onClick={() => handleCurrentPlan(subscription.id)}
              >
                {loadingPlan === subscription.id ? (
                  <Spinner size="sm" />
                ) : (
                  'Choose Plan'
                )}
              </button>
            )}
          </div>
        ))}
      </HStack>
    </VStack>
  );
}
