import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import FormFieldWrapper from './FormFieldWrapper'


export default function CustomInputPassword(props) {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    return (
        <FormFieldWrapper {...props}>
            <InputGroup size="md">
                <Input pr="4.5rem" placeholder={props.placeholder} type={show ? 'text' : 'password'} />
                <InputRightElement width="3rem">
                    <Button size="xs" fontSize={'xl'} variant={'link'} onClick={handleClick}>
                        {show ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </InputRightElement>
            </InputGroup>
        </FormFieldWrapper>
    )
}
