import * as yup from 'yup'

export const registerDefault = {
    email: '',
    password: '',
}

export const registerValidation = yup.object().shape({
    email: yup.string().lowercase('All characters should be in lowercase').trim().required('Email is required'),
    password: yup.string().trim().required('Password is required'),
})
