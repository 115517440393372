import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import { PublicRoute, PrivateRoute } from './components/route-compoenents';

import Home from './pages/landing';
import GetStartedPage from './pages/auth/get-started';
import LoginPage from './pages/auth/login';
import ForgotPasswordPage from './pages/auth/forgot-password';
import SweeperPage from './pages/main'
import SubScriptionPage from './pages/subscription';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          }/>
          <Route path="/login" element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }/>
        <Route path="/sweeper" element={
          <PrivateRoute>
            <SweeperPage />
          </PrivateRoute>
        }/>
        <Route path="/checkout" element={
          <PrivateRoute>
            <SubScriptionPage />
          </PrivateRoute>
        }/>
          <Route path="/register" element={<GetStartedPage />} />
          <Route path="/forgot_password" element={<ForgotPasswordPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
