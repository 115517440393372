import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, HStack, Stack, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { registerDefault, registerValidation } from '../../validator/register';
import CustomCheckbox from '../form-components/CustomCheckbox';
import CustomInput from '../form-components/CustomInput';
import CustomInputPassword from '../form-components/CustomInputPassword';
import { useNavigate } from 'react-router-dom';
import { useRegister } from '../../hooks/auth';

export default function Register() {
    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: 'all',
        defaultValues: registerDefault,
        resolver: yupResolver(registerValidation)
    });
    const { mutate: register, isPending: registeringIn } = useRegister()
    const onSubmit = formData => {
        register(formData)
    };

    let navigate = useNavigate();

    return (
        <Stack spacing={8} className="w-[95%] md:w-[417px]  bg-white rounded-[44px] shadow-custom p-[20px]">
            <Stack>
                <Text className="text-[24px] leading-[29px] font-bold ">Sign up</Text>
                <HStack>
                    <Text>Already have an account?</Text>
                    <ChakraLink onClick={() => navigate('/login')} className='!text-[#3E4CF0]' fontWeight="bold">
                        Sign in
                    </ChakraLink>
                </HStack>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6}>
                    <CustomInput placeholder="Email address" name="email" control={control} errors={errors} />
                    <CustomInputPassword placeholder="Password" name="password" control={control} errors={errors} />
                    <Button size="lg" isLoading={registeringIn} type="submit" className='!bg-[#3E4CF0] !text-white'>
                        Sign up
                    </Button>

                    <Flex gap={4}>
                        <Box>
                            <CustomCheckbox name="agree" control={control} errors={errors} size="lg" />
                        </Box>
                        <Box className='text-[12px] leading-[14.5px] text-[#898989]'>
                            By clicking Create account, I agree that I have read and accepted the{' '}
                            <ChakraLink as={Link} to="/terms-of-use" className='!text-[#3E4CF0]' fontWeight="bold">
                                Terms of Use
                            </ChakraLink>
                            {' '}and{' '}
                            <ChakraLink as={Link} to="/privacy-policy" className='!text-[#3E4CF0]' fontWeight="bold">
                                Privacy Policy
                            </ChakraLink>.
                        </Box>
                    </Flex>
                </Stack>
            </form>
        </Stack>
    );
}
