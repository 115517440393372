import ForgotPassword from '../../components/user/ForgotPassword'
import StandalonePage from '../../components/user/StandalonePage'

export default function ForgotPasswordPage() {
    return (
        <>
            <StandalonePage>
                <ForgotPassword />
            </StandalonePage>
        </>
    )
}
