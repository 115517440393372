import { Input } from "@chakra-ui/react"
import FormFieldWrapper from "./FormFieldWrapper"


export default function CustomInput(props) {
    return (
        <FormFieldWrapper {...props}>
            <Input />
        </FormFieldWrapper>
    )
}
