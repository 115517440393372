import Login from '../../components/user/Login'
import StandalonePage from '../../components/user/StandalonePage'

export default function LoginPage() {
    return (
        <>
            <StandalonePage>
                <Login />
            </StandalonePage>
        </>
    )
}
