import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  VStack,
  Text,
  useToast,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import {
  uploadSong,
  cleanExportSong,
  patchSong,
  previewSong,
} from '../hooks/song';
import {
  FaFileUpload,
  FaPlay,
  FaPause,
  FaStepBackward,
  FaStepForward,
} from 'react-icons/fa';
import WaveSurfer from 'wavesurfer.js';

const Word = ({ word, index, active, currentTime, onToggle }) => {
  return (
    <Text
      key={index}
      className={`w-fit cursor-pointer inline-block`}
      style={{
        color: active ? 'red' : 'black',
        backgroundColor:
          word.start_time <= currentTime && word.end_time >= currentTime
            ? 'rgba(0, 0, 255, .1)'
            : 'rgba(0, 0, 255, 0)',
        height: 'fit-content',
      }}
      onClick={() => onToggle()}
    >
      {word.word}&nbsp;
    </Text>
  );
};

const FileUploadComponent = () => {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const onDrop = useCallback(acceptedFiles => {
    // Take the first file if multiple files are dropped (or you can handle multiple files if needed)
    setFile(acceptedFiles[0]);
  }, []);

  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // If there's a file and it's an audio file, create an object URL and set it to state
    if (file) {
      const src = URL.createObjectURL(file);
      setAudio(src);

      const handleKeyPress = event => {
        event.preventDefault();
        // Stop the propagation of the space key event
        event.stopPropagation();
        playBtnRef.current.click();
      };

      // Add keydown event listener to the document
      document.addEventListener('keydown', handleKeyPress);

      // Cleanup function to revoke object URL to avoid memory leaks
      return () => {
        URL.revokeObjectURL(src);
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [file]);

  function skip(seconds) {
    let newPosition = wavesurfer.current.getCurrentTime() + seconds;
    if (newPosition < 0) newPosition = 0;
    if (newPosition > wavesurfer.current.getDuration())
      newPosition = wavesurfer.current.getDuration();
    setCurrentTime(newPosition);
    wavesurfer.current.seekTo(newPosition / wavesurfer.current.getDuration());
  }

  const handlePlayBackward = () => {
    if (wavesurfer.current) {
      skip(-5);
    }
  };

  // Function to navigate 5 seconds forward
  const handlePlayForward = () => {
    if (wavesurfer.current) {
      skip(5);
    }
  };

  // Function to play/pause the audio
  const handlePlayPause = () => {
    if (wavesurfer.current) {
      if (!isPlaying) {
        setIsPlaying(true);
        wavesurfer.current.play();
      } else {
        setIsPlaying(false);
        wavesurfer.current.pause();
      }
    }
  };

  const handlePlaySelectedWordPoint = word => {
    if (wavesurfer.current) {
      wavesurfer.current.seekTo(
        word.start_time / wavesurfer.current.getDuration()
      );
      if (!isPlaying) {
        wavesurfer.current.play();
        setIsPlaying(true);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'audio/*',
  });

  const [data, setData] = useState(null);
  const [words, setWords] = useState(null);
  const [isUploading, setUploading] = useState(false);

  const handleUpload = () => {
    if (isUploading) {
      toast({
        title: 'Uploading...',
        description: "We're uploading your audio!",
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (file) {
      setUploading(true);
      let formData = new FormData();
      formData.append('song', file);
      // Replace 'uploadSong' with your actual upload function
      toast({
        title: 'Uploading...',
        description: "We're uploading your audio!",
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      uploadSong({
        payload: formData,
      })
        .then(data => {
          toast({
            title: 'Success...',
            description: 'Successfully uploaded.',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          // Assuming 'data' is the response from the server with the structure you provided
          setData(data);
          console.log(data);
          const temp = data.words.map(word => ({ selected: false }));
          data.swear_words.forEach(word => {
            temp[word.index - 1].selected = true;
          });
          setWords(temp);
          setUploading(false);
        })
        .catch(err => {
          toast({
            title: 'Error While Uploading...',
            description: 'Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          setUploading(false);
        });
      // Show toast when upload starts
    }
  };
  const [currentTime, setCurrentTime] = useState(0); // State to store the current time of audio

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time % 1) * 1000); // Get the fraction of the second and convert to milliseconds

    // Format minutes, seconds, and milliseconds to always show two digits
    const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}`;
    const formattedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`;
    const formattedMilliseconds = `${
      milliseconds < 100 ? (milliseconds < 10 ? '00' : '0') : ''
    }${milliseconds}`;

    // Combine them into a single string
    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds.substring(
      0,
      2
    )}`; // Using substring to ensure only two digits for milliseconds
  };

  const downloadFile = url => {
    // Create a new anchor element dynamically
    const link = document.createElement('a');
    link.href = url;
    link.download = ''; // Provide the filename you want to download as

    // Append to the document
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const [isExporting, setExporting] = useState(false);
  const [isPreviewing, setPreviewing] = useState(false);

  const getBlob = url => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          resolve(blob);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const handlePreviewSong = async () => {
    setPreviewing(true);

    const patchPayload = data.words.filter(
      (word, index) => words[index].selected
    );

    patchSong(data['song_id'], patchPayload).then(() => {
      previewSong(data['song_id'])
        .then(async res => {
          const src = URL.createObjectURL(await getBlob(res.url));
          setAudio(src);

          construct(src);

          setPreviewing(false);
        })
        .catch(err => {
          setPreviewing(false);
        });
    });
  };

  const handleExport = () => {
    if (isExporting) {
      toast({
        title: 'Exporting...',
        description: "We're exporting your cleaned audio!",
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (file) {
      setExporting(true);
      toast({
        title: 'Exporting...',
        description: "We're exporting your cleaned audio!",
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      const patchPayload = data.words.filter(
        (word, index) => words[index].selected
      );
      patchSong(data['song_id'], patchPayload)
        .then(res => {
          cleanExportSong(data['song_id'])
            .then(data => {
              toast({
                title: 'Success...',
                description: 'Successfully exported cleaned audio.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top',
              });
              // Assuming 'data' is the response from the server with the structure you provided
              downloadFile(data.url);
              setExporting(false);
            })
            .catch(err => {
              toast({
                title: 'Error While Exporting...',
                description: 'Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
              });
              setExporting(false);
            });
        })
        .catch(err => {
          toast({
            title: 'Error While Patching...',
            description: 'Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          setExporting(false);
        });
      // Show toast when upload starts
    }
  };

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const playBtnRef = useRef(null);
  // Create new WaveSurfer instance
  const formWaveSurferOptions = ref => ({
    container: ref,
    waveColor: '#eee',
    progressColor: '#3E4CF0',
    cursorColor: '#3E4CF0',
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 50,
    normalize: true,
    partialRender: true,
  });

  const construct = audio => {
    if (waveformRef.current && !wavesurfer.current) {
      wavesurfer.current = WaveSurfer.create(
        formWaveSurferOptions(waveformRef.current)
      );
    }
    if (!wavesurfer.current) return;
    wavesurfer.current.load(audio);
    wavesurfer.current.on('audioprocess', () => {
      if (wavesurfer.current) {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      }
    });

    wavesurfer.current.on('seek', () => {
      setCurrentTime(wavesurfer.current.getCurrentTime());
    });

    wavesurfer.current.on('finish', () => {
      setIsPlaying(false);
      setCurrentTime(0);
      // Here you can add any other actions you need when playback ends
    });

    // Removes event listeners and wavesurfer instance when component unmounts
    return () => wavesurfer.current && wavesurfer.current.destroy();
  };

  useEffect(() => {
    construct(audio);
  }, [audio]);

  const handleProgressClick = event => {
    const bbox = waveformRef.current.getBoundingClientRect();
    const posX = event.clientX - bbox.left; // Get the horizontal position of the click
    const width = bbox.width;
    const percentage = posX / width;
    wavesurfer.current.seekTo(percentage);
    setCurrentTime(wavesurfer.current.getCurrentTime());
  };

  return (
    <VStack spacing={4} alignItems="center" justifyContent="center">
      {
        <Box className="w-full">
          <Box className="flex justify-between gap-[50px] min-h-[360px]">
            <Box
              className="shadow-custom1 w-full block bg-white p-[20px] flex-wrap max-h-[550px] overflow-auto"
              style={{
                wordBreak: 'break-word',
              }}
            >
              {!data ? (
                <>
                  <Box
                    {...getRootProps()}
                    p={10}
                    borderWidth={2}
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderRadius="md"
                    w="full"
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ borderColor: 'gray.400' }}
                    h={500}
                    onKeyDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className="flex justify-center items-center text-[20px] "
                  >
                    {file ? (
                      <Box className="flex flex-col gap-[30px]">
                        <Text>
                          {file.name} - {file.size} bytes
                        </Text>
                        <Button
                          leftIcon={
                            isUploading ? (
                              <Spinner size="sm" />
                            ) : (
                              <FaFileUpload />
                            )
                          }
                          colorScheme="blue"
                          isDisabled={!file}
                          onClick={e => {
                            e.stopPropagation(); // This stops the click event from bubbling up to the parent elements
                            handleUpload(); // Continue with your upload function
                          }}
                          className="z-[9999] !bg-[#3E4CF0]"
                        >
                          Upload
                        </Button>
                      </Box>
                    ) : (
                      <>
                        <input {...getInputProps()} />

                        {isDragActive ? (
                          <Text>Drop the file here ...</Text>
                        ) : (
                          <Text>
                            Drag 'n' drop audio file here, or click to select
                            file
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                </>
              ) : (
                data.words.map((word, index) => {
                  return (
                    <Word
                      key={index}
                      word={word}
                      index={index}
                      active={words[index].selected}
                      currentTime={currentTime}
                      onToggle={() => {
                        let temp = JSON.parse(JSON.stringify(words));
                        temp[index].selected = !temp[index].selected;
                        setWords(temp);
                        handlePlaySelectedWordPoint(word);
                      }}
                    />
                  );
                })
              )}
            </Box>
            <Box className="shadow-custom1 w-[200px] max-h-[550px] overflow-auto flex bg-white p-[10px] flex-col">
              {words &&
                words.length &&
                words.map(
                  (word, index) =>
                    word.selected && (
                      <Text key={'swear' + index}>
                        {data.words[index].word}
                      </Text>
                    )
                )}
            </Box>
          </Box>
          {audio && (
            <Box className="flex justify-center flex-col items-center">
              <Box className="audio-controls flex space-x-2 mt-4 items-center gap-[20px]">
                <Text className=" bg-[#3E4CF0] px-[10px] py-[8px] text-white rounded-[37px] w-[100px] text-center">
                  {formatTime(currentTime)}
                </Text>
                <IconButton
                  className="!bg-white"
                  icon={<FaStepBackward />}
                  onClick={handlePlayBackward}
                  aria-label="Backward"
                />
                <IconButton
                  className="!bg-white"
                  icon={isPlaying ? <FaPause /> : <FaPlay />}
                  onClick={handlePlayPause}
                  aria-label="Play/Pause"
                  ref={playBtnRef}
                />
                <IconButton
                  className="!bg-white"
                  icon={<FaStepForward />}
                  onClick={handlePlayForward}
                  aria-label="Forward"
                />
                <Button
                  className="h-[36px] !bg-[#3E4CF0] px-[10px] py-[6px] !text-white rounded-[37px]"
                  onClick={handleExport}
                >
                  {isExporting && <Spinner size="sm" className="!mr-[10px]" />}
                  Export
                </Button>
                <Button
                  className="h-[36px] !bg-[#3E4CF0] px-[10px] py-[6px] !text-white rounded-[37px]"
                  onClick={handlePreviewSong}
                >
                  {isPreviewing && <Spinner size="sm" className="!mr-[10px]" />}
                  Preview
                </Button>
              </Box>
            </Box>
          )}
          {audio && (
            <div
              ref={waveformRef}
              className="mt-4"
              onClick={handleProgressClick}
            ></div>
          )}
        </Box>
      }
    </VStack>
  );
};

export default FileUploadComponent;
