import logo from '../../assets/img/logo.png'
import { Link } from 'react-router-dom'


export default function StandalonePage({ children }) {
    return (
        <div className="flex justify-center items-center gap-[0px] md:gap-[50px] h-[100vh] px-[20px] md:px-[50px] flex-col md:flex-row mb-[50px] md:mb-[0px]">
            <Link href="/" >
                <img src={logo} alt="logo" />
            </Link>
            {children}
        </div>
    )
}
