import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { get, omit } from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'



export default function FormFieldWrapper(props) {
    const renderChildren = (props) => {
        return React.Children.map(props.children, child => {
            return React.cloneElement(child, omit(props, ['children']))
        })
    }

    const isError = !!props.errors[props.name]
    const errorMessage = get(props.errors[props.name], 'message')
    const label = get(props, 'label')
    const name = get(props, 'name')
    const isRequired = get(props, 'isRequired')

    return (
        <FormControl isInvalid={isError} isRequired={isRequired}>
            {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Controller
                name={name}
                control={props.control}
                render={({ field, fieldState: { isDirty } }) => {
                    const necessaryProps = omit({ ...field, ...props }, [
                        'errors',
                        'control',
                        'label',
                        'setValue',
                        'setChecked',
                    ])

                    return renderChildren(necessaryProps)
                }}
            />
            {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </FormControl>
    )
}
