import {jwtDecode} from 'jwt-decode';

const isAuthenticated = () => {
  const _as_token = localStorage.getItem('_as_token');

  if (!_as_token) return false;

  try {
    const decoded = jwtDecode(_as_token);
    // Check if the token has expired.
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return false;
  }
};

export default isAuthenticated;
