import { forgotDefault, forgotValidation } from '../../validator/forgot-password'
import { Button, Center, HStack, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import CustomInput from '../form-components/CustomInput'
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword() {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: forgotDefault,
        resolver: yupResolver(forgotValidation),
    })

    const onSubmit = async (formData) => {
        console.log(formData)
    }

    let navigate = useNavigate();

    return (
        <Stack spacing={8} className="w-[95%] md:w-[417px] bg-white rounded-[44px] shadow-custom p-[20px]">
            <Stack>
                <Text className="text-[24px] leading-[29px] font-bold">Forgot password?</Text>
                <Text>No worries! Just enter your email and we&apos;ll send you a reset password link.</Text>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6}>
                    <CustomInput placeholder="Email address" name={'email'} control={control} errors={errors} />
                    <Button size="lg" type="submit" className='!bg-[#3E4CF0] !text-white'>
                        Send Recovery Email
                    </Button>

                    <Center>
                        <HStack>
                            <Text>Just remember?</Text>
                            <Link  onClick={() => navigate('/register')} className='!text-[#3E4CF0]' fontWeight={'bold'} >
                                Sign up
                            </Link>
                        </HStack>
                    </Center>
                </Stack>
            </form>
        </Stack>
    )
}
