import React, { useEffect, useState } from 'react';
import { Box, Text, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { HiDotsVertical } from 'react-icons/hi'; // Using Heroicons for the vertical dots
import FileUploadComponent from '../components/sweeper';
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';

export default function SweeperPage() {
    // Function to handle logout
    const handleLogout = () => {
        // Destroy the authentication cookie
        localStorage.removeItem('_as_token');

        // Redirect to login or any other route
        window.location.href = '/login';
    };

    useEffect(() => {
        let userdata = JSON.parse(localStorage.getItem('userdata'));
        if(userdata?.credit_balance){
            setCredit(userdata.credit_balance);
        }
        axiosInstance.get('/users/')
            .then(res => {
                userdata = res.data;
                setCredit(userdata.credit_balance);
                localStorage.setItem('userdata', JSON.stringify(userdata));
            })
            .catch(err => {
                console.log(err);
            })
      }, [])
      

    let navigate = useNavigate();
      const [credit, setCredit] = useState(0);
    return (
        <>
            <Box bgColor={'#fff'} className="px-[20px]">
                <Box className="flex justify-between items-center h-[70px]">
                    <img src={logo} alt="logo" className="w-[120px]" />
                    <Box className="flex justify-between items-center gap-[20px]">
                        <Text>{credit} credits</Text>
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<HiDotsVertical />}
                                variant="ghost"
                                aria-label="More options"
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 !text-[30px] !text-[#222222]"
                                size="lg"
                            />
                            <MenuList>
                                {/* Additional Menu Items can be added here */}
                                <MenuItem onClick={() => { navigate('/checkout')}}>Subscribe</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Box>
                <FileUploadComponent />
            </Box>
        </>
    );
}
