import CheckoutPage from "../components/checkout"
import React from 'react';
import { Box, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { HiDotsVertical } from 'react-icons/hi'; // Using Heroicons for the vertical dots
import logo from '../assets/img/logo.png';
import { useNavigate } from "react-router-dom";

export default function SubScriptionPage() {
    const handleLogout = () => {
        // Destroy the authentication cookie
        localStorage.removeItem('_as_token');

        // Redirect to login or any other route
        window.location.href = '/login';
    };
    
    const navigate = useNavigate();
    return (
        <div className="h-[100vh] flex items-center flex-col">
            <Box className="flex justify-between items-center w-full absolute top-[0] px-[20px] h-[70px]">
                    <img src={logo} alt="logo" className="w-[120px]" />
                    <Box className="flex justify-between items-center gap-[20px]">
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<HiDotsVertical />}
                                variant="ghost"
                                aria-label="More options"
                                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 !text-[30px] !text-[#222222]"
                                size="lg"
                            />
                            <MenuList>
                                {/* Additional Menu Items can be added here */}
                                <MenuItem onClick={() => {navigate('/sweeper')}}>Sweeper</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Box>
            <CheckoutPage />
        </div>
    )
}
