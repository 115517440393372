import { Button, Center, Container, HStack, Image, Text, VStack } from '@chakra-ui/react'
import logo from '../../assets/img/logo.png'
import { useNavigate } from 'react-router-dom';

export default function TempLanding() {
    let navigate = useNavigate();
    return (
        <Center minH={'100dvh'}>
            <Container>
                <VStack spacing={8}>
                    <img src={logo} alt="logo"/>
                    <HStack>
                        <Button  onClick={() => navigate('/register')} className='!bg-[#3E4CF0] !text-white'>
                            Get Started
                        </Button>
                    </HStack>
                </VStack>
            </Container>
        </Center>
    )
}
